import React from "react";

export default function ReadMoreIcon() {
  return (
    <span className="sc-Icon sc-Icon--s sc-Icon--blue sc-ListItem-icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        aria-hidden="true"
      >
        <polygon points="3.781 14.586 10.364 8.003 3.787 1.413 5.204 0 13.191 8.003 5.195 16" />
      </svg>
    </span>
  );
}
